import React from 'react';
import { useSelector } from 'react-redux';

import {
    wrapper,
    attachmentListWrapper,
    imOwnerWrapper,
    himIsAnOwnerWrapper,
    messageWrapper,
    authorLabel,
    avatarHolder,
    contentText,
} from './message.module.scss';
import { getCurrentProfileId } from '../../../redux/profile/profile.selectors';

import Avatar from '../../atoms/avatar';
import AttachmentPill from '../attachment-pill/attachment-pill';
import Markdown from '../../hoc/markdown';

const Message = ({ message }) => {
    const { content, createdAt, attachments, author } = message;

    const profileId = useSelector(getCurrentProfileId);

    const contentWithLinks = getTextWithLinks(content);

    return (
        <div
            className={`${wrapper} ${
                profileId === author.profileId ? imOwnerWrapper : himIsAnOwnerWrapper
            }`}
        >
            <div className={avatarHolder}>
                <Avatar avatarUri={author.avatarUri} />
            </div>
            <div>
                <div className={messageWrapper}>
                    <Markdown className={contentText}>{contentWithLinks}</Markdown>
                    <ul className={attachmentListWrapper}>
                        {attachments.map((item) => (
                            <li key={`message-attachment-${item.file.fileId}`}>
                                <AttachmentPill
                                    data={item}
                                    reverseColor={profileId !== author.profileId}
                                />
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={authorLabel}>
                    {`${author.firstName} ${author.lastName} ${createdAt}`}
                </div>
            </div>
        </div>
    );
};

// source https://bobbyhadz.com/blog/replace-plain-urls-with-links-using-javascript
function getTextWithLinks(text) {
    const expressionWithHttp = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/gi;
    const regex = new RegExp(expressionWithHttp);
    return text.replace(regex, "<a href='$1' target='blank' rel='noreferrer noopener'>$1</a>");
}

export default Message;
