import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    title,
    button,
    closed,
    points as pointsClass,
    image,
    ketonImage,
    closing,
    closeIcon,
    content,
    pointsWidgetBalance,
    contentWrapper,
} from './points-widget-balance.module.scss';
import { config } from '../../config';
import CloseIcon from '../../assets/images/svg/icon-close.svg';
import { selectGamificationHistoryLoading } from '../../redux/gamification/gamification.selectors';
import { selectIsUserVerificationFinished } from '../../redux/user/user.selectors';

import AnimatedKeton from '../atoms/animated-keton';
import Loader from '../atoms/loader';

const { apiStatusMap } = config;

export interface IPointsWidgetBalanceProps {
    points: number;
}

type OpenStateType = 'opened' | 'closing' | 'closed' | 'opening';

export default function PointsWidgetBalance({ points }: IPointsWidgetBalanceProps) {
    const [openState, setOpenState] = useState<OpenStateType>('closed');
    const isGamificationHistoryLoading =
        useSelector(selectGamificationHistoryLoading) === apiStatusMap.loading;
    const isUserVerificationFinished = useSelector(selectIsUserVerificationFinished);
    const isLoading = isGamificationHistoryLoading || !isUserVerificationFinished;

    const handleClose = useCallback(() => {
        setOpenState('closing');
    }, []);

    const handleOpen = useCallback(() => {
        if (openState !== 'opened') {
            setOpenState('opened');
        }
    }, [openState]);

    const handleTransitionEnd = useCallback(() => {
        if (openState === 'closing') {
            setOpenState('closed');
        }
    }, [openState]);

    return (
        <div
            className={`${pointsWidgetBalance} ${getOpenStateClassName(openState)}`}
            onTransitionEnd={handleTransitionEnd}
            onClick={handleOpen}
        >
            {isLoading && openState === 'opened' && <Loader fullContainer />}
            <button className={button} onClick={handleClose}>
                <CloseIcon className={closeIcon} />
            </button>
            <div className={contentWrapper}>
                <div className={image}>
                    <AnimatedKeton className={ketonImage} />
                </div>
                <div className={content}>
                    <span className={title}>Twoje ketony</span>
                    <span className={pointsClass}>{points}</span>
                </div>
            </div>
        </div>
    );
}

const getOpenStateClassName = (openState: OpenStateType) => {
    switch (openState) {
        case 'closing':
            return closing;
        case 'closed':
            return closed;
        case 'opened':
        default:
            return '';
    }
};
