import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { title, loader, grid, slider, prev, next, dots } from './welcome.module.scss';
import { getCurrentProfile } from '../../../redux/profile/profile.selectors';
import useSlider from '../../../hooks/use-slider';
import {
    selectWelcomeSlides,
    selectWelcomeSlidesStatus,
} from '../../../redux/welcome-slides/welcome-slides.selectors';
import {
    clearWelcomeSlidesAction,
    getWelcomeSlidesAction,
} from '../../../redux/welcome-slides/welcome-slides.actions';

import Title from '../../atoms/title';
import Loader from '../../atoms/loader';
import ArrowButton from '../../atoms/arrow-button';
import SliderDots from '../../atoms/slider-dots';
import WelcomeSlide from '../../molecules/welcome-slide';

const sliderConfig = {
    arrows: false,
    autoplay: false,
    dots: false,
    speed: 700,
};

const Welcome = () => {
    const dispatch = useDispatch();
    const currentProfile = useSelector(getCurrentProfile);
    const welcomeSlides = useSelector(selectWelcomeSlides);
    const status = useSelector(selectWelcomeSlidesStatus);

    const {
        getPrev,
        getNext,
        sliderSettings,
        slideIndex,
        setSlideIndex,
        isChanging,
        sliderRef,
    } = useSlider(sliderConfig);

    useEffect(() => {
        dispatch(getWelcomeSlidesAction());
        return () => {
            dispatch(clearWelcomeSlidesAction());
        };
    }, [dispatch]);

    return (
        <>
            <Title Tag="h3" className={title}>
                Witaj, {currentProfile && currentProfile.firstName}!
            </Title>
            {!status || (status === 'loading' && <Loader className={loader} />)}
            {status === 'success' && (
                <>
                    <div className={grid}>
                        <div className={slider}>
                            <SlickSlider {...sliderSettings} ref={sliderRef}>
                                {welcomeSlides.map((welcomeSlide) => {
                                    return (
                                        <WelcomeSlide
                                            welcomeSlide={welcomeSlide}
                                            key={`welcome-slide-${welcomeSlide.articleId}`}
                                            isDisabled={isChanging}
                                        />
                                    );
                                })}
                            </SlickSlider>
                        </div>
                        {welcomeSlides.length > 1 && (
                            <>
                                <ArrowButton
                                    className={prev}
                                    onClick={getPrev}
                                    disabled={isChanging}
                                    dark={true}
                                />
                                <ArrowButton
                                    className={next}
                                    onClick={getNext}
                                    rotateDeg={180}
                                    disabled={isChanging}
                                    dark={true}
                                />
                                <SliderDots
                                    className={dots}
                                    slides={welcomeSlides}
                                    onClick={setSlideIndex}
                                    activeSlideIndex={slideIndex}
                                    disabled={isChanging}
                                    dark={true}
                                />
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default Welcome;
