import { config } from '../../config';

const { apiStatusMap } = config;

export const selectLoaderByEntity = (state, entity) => {
    return state.ui?.loaders?.[entity];
};

export const selectIsEntityLoading = (entity) => (state) => {
    return selectLoaderByEntity(state, entity) === apiStatusMap.loading;
};

export const selectIsEntityError = (entity) => (state) => {
    return selectLoaderByEntity(state, entity) === apiStatusMap.fail;
};
