// extracted by mini-css-extract-plugin
export var button = "points-widget-balance-module--button--AYNBZ";
export var closeIcon = "points-widget-balance-module--close-icon--lHzGE";
export var closed = "points-widget-balance-module--closed--+VMNx";
export var closing = "points-widget-balance-module--closing--VmDB+";
export var content = "points-widget-balance-module--content--gS9P2";
export var contentWrapper = "points-widget-balance-module--content-wrapper---tFcm";
export var image = "points-widget-balance-module--image--gkXEV";
export var ketonImage = "points-widget-balance-module--keton-image--bBFPM";
export var points = "points-widget-balance-module--points--qpV9t";
export var pointsWidgetBalance = "points-widget-balance-module--points-widget-balance--fh90Q";
export var title = "points-widget-balance-module--title--maUl2";