import { getCourse } from '../../../communication/courses';

const moduleName = `[CURRENT COURSE]`;

export const actions = {
    SET_COURSE_ID: `${moduleName} set course id`,

    LOADING_COURSE_START: `${moduleName} start loading`,
    LOADING_COURSE_SUCCESS: `${moduleName} success loading`,
    LOADING_COURSE_ERROR: `${moduleName} error loading`,

    CLEAR_COURSE: `${moduleName} clear data`,

    SET_CHOSEN_CATEGORY: `${moduleName} set chosen category`,

    SET_CHOSEN_MOVIE: `${moduleName} set chosen movie`,

    CLEAR_CHOSEN_MOVIE: `${moduleName} clear chosen Movie`,
};

export const setCourseId = (payload) => ({
    type: actions.SET_COURSE_ID,
    payload,
});

export const clearCourse = () => ({
    type: actions.CLEAR_COURSE,
});

const loadingCourseStart = () => ({
    type: actions.LOADING_COURSE_START,
});

const loadingCourseSuccess = (payload) => ({
    type: actions.LOADING_COURSE_SUCCESS,
    payload,
});

const loadingCourseError = (payload) => ({
    type: actions.LOADING_COURSE_ERROR,
    payload,
});

export const setChosenCategory = (payload) => ({
    type: actions.SET_CHOSEN_CATEGORY,
    payload,
});

export const setChosenMovie = (payload) => ({
    type: actions.SET_CHOSEN_MOVIE,
    payload,
});

export const clearAndSetDefaultMovieId = (payload) => ({
    type: actions.CLEAR_CHOSEN_MOVIE,
    payload,
});

export const fetchCourse = () => async (dispatch, getState) => {
    dispatch(loadingCourseStart());

    const courseId = getState().courses.currentCourse.courseId;

    try {
        const res = await getCourse(courseId);

        if (res.status === 200) {
            dispatch(loadingCourseSuccess(res.data));
        }
    } catch (error) {
        dispatch(loadingCourseError(error));
    }
};
