import { combineReducers } from 'redux';

import allCourses from './all-reducers.reducer';
import myCourses from './my-courses.reducer';
import currentCourse from './current-course.reducer';
import cleanEnhancer from '../../reducerEnhancers/cleanEnhancer';
import { types } from '../../user/user.actions';

const coursesReducer = combineReducers({
    allCourses,
    myCourses,
    currentCourse,
});

export default cleanEnhancer(coursesReducer, types.CLEAR_USER_DATA);
