import React from 'react';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';

import {
    content,
    imageWrapper,
    wrapper,
    image,
    tvIconWrapper,
    tvIcon,
    iconInfoWrapper,
    lockIconWrapper,
    listItem,
    iconLock,
    timeIcon,
    bottomHolder,
    buttonHolder,
    placeHolder,
    placeHolderContent,
    timeIconWrapper,
    ratioClass,
} from './courses-list-item.module.scss';
import TvIcon from '../../../../../assets/images/svg/tv.svg';
import TimeIcon from '../../../../../assets/images/svg/time.svg';
import OkIcon from '../../../../../assets/images/svg/ok.svg';
import IconLock from '../../../../../assets/images/svg/icon-lock.svg';
import { addSingleProduct } from '../../../../../redux/shop/cart/cart.actions';
import { humanizedSeconds } from '../../../../../communication/utils';
import { addModalAction } from '../../../../../redux/actions/actions-modals';

import Button from '../../../../atoms/button';
import RatioImage from '../../../../atoms/ratio-image';

const CoursesListItem = ({ item }) => {
    const dispatch = useDispatch();
    const {
        points,
        moviesCount,
        moviesTimeCount,
        coverListUri,
        name,
        isBought,
        price,
        productId,
        id,
        canBuy,
    } = item;

    const navigateToCourse = () => {
        navigate(`/app/kurs/${id}`);
    };

    const handleAddToCart = () => {
        if (!canBuy) {
            dispatch(
                addModalAction({
                    title: 'Produkt chwilowo niedostępny w sprzedaży',
                })
            );
            return;
        }
        const payload = {
            productId: productId,
            variantId: null,
        };
        dispatch(addSingleProduct(payload));
    };

    return (
        <article className={wrapper}>
            {!isBought && (
                <div className={lockIconWrapper}>
                    <IconLock className={iconLock} />
                </div>
            )}
            <div className={imageWrapper}>
                <RatioImage
                    src={coverListUri}
                    className={image}
                    alt="Miniatura kursu"
                    ratioClass={ratioClass}
                />

                <div className={placeHolder}>
                    <div className={placeHolderContent}>{name}</div>
                </div>
            </div>
            <div className={bottomHolder}>
                <div className={iconInfoWrapper}>
                    <div className={tvIconWrapper}>
                        <TvIcon className={tvIcon} />
                        Filmy: {moviesCount}
                    </div>
                    <div className={timeIconWrapper}>
                        <TimeIcon className={timeIcon} />
                        {humanizedSeconds(moviesTimeCount)}
                    </div>
                </div>
                {points && (
                    <ul>
                        {points.map((point, index) => (
                            <li key={`point--${index}`} className={listItem}>
                                <OkIcon />
                                <span className={content}>{point.content}</span>
                            </li>
                        ))}
                    </ul>
                )}
                <div className={buttonHolder}>
                    {isBought ? (
                        <Button color={'yellow'} fullwidth={true} onClick={navigateToCourse}>
                            PRZEJDŹ
                        </Button>
                    ) : (
                        <>
                            <Button color="blank" fullwidth={true} onClick={navigateToCourse}>
                                DOWIEDZ SIĘ WIĘCEJ O KURSIE
                            </Button>
                            <Button
                                color={'yellow'}
                                fullwidth={true}
                                onClick={handleAddToCart}
                                disabled={!canBuy}
                                isDisabledClickable={!canBuy}
                            >
                                KUP TEN KURS ZA {price.grossDisplay}
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </article>
    );
};

export default CoursesListItem;
