import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { list, titleEmptyList, wrapperEmptyList } from './all-corses.module.scss';
import {
    clearAllCourses,
    fetchAllCourses,
} from '../../../../../redux/courses/actions/all-courses-actions';

import List from '../../../../molecules/list/list';
import Loader from '../../../../atoms/loader';
import CoursesListItem from '../courses-list-item/courses-list-item';

const PER_PAGE = 50;

const AllCourses = () => {
    const dispatch = useDispatch();
    const { loading, courses } = useSelector((state) => {
        return {
            courses: Object.values(state.courses.allCourses.data),
            loading: state.courses.allCourses.loading,
        };
    });

    useEffect(() => {
        dispatch(fetchAllCourses(PER_PAGE));
        return () => {
            dispatch(clearAllCourses());
        };
    }, [dispatch]);

    if (loading) {
        return <Loader />;
    }

    return courses.length > 0 ? (
        <List listClassName={list} items={courses} ItemComponent={CoursesListItem} />
    ) : (
        <div className={wrapperEmptyList}>
            <span className={titleEmptyList}>Brak kursów</span>
        </div>
    );
};

export default AllCourses;
