import { entitiesMap, getFullActionName } from '../../reusable-reducers/entities-map';
import { getAllCourses } from '../../../communication/courses';

const actions = {
    FETCH_START: getFullActionName(
        entitiesMap.allCourses.baseName,
        entitiesMap.allCourses.methodName.fetch,
        entitiesMap.allCourses.allowedActions.start
    ),
    FETCH_SUCCESS: getFullActionName(
        entitiesMap.allCourses.baseName,
        entitiesMap.allCourses.methodName.fetch,
        entitiesMap.allCourses.allowedActions.success
    ),
    FETCH_ERROR: getFullActionName(
        entitiesMap.allCourses.baseName,
        entitiesMap.allCourses.methodName.fetch,
        entitiesMap.allCourses.allowedActions.error
    ),
    CLEAR_DATA: getFullActionName(
        entitiesMap.allCourses.baseName,
        entitiesMap.allCourses.methodName.fetch,
        entitiesMap.allCourses.allowedActions.clearData
    ),
};

export const fetchAllCoursesStart = () => ({
    type: actions.FETCH_START,
});

const fetchAllCoursesSuccess = (payload) => ({
    type: actions.FETCH_SUCCESS,
    payload,
});

const fetchAllCoursesError = (payload) => ({
    type: actions.FETCH_ERROR,
    payload,
});

export const fetchAllCourses = (perPage) => async (dispatch) => {
    dispatch(fetchAllCoursesStart());

    try {
        const res = await getAllCourses(perPage);

        if (res.status === 200) {
            dispatch(fetchAllCoursesSuccess(res.data));
        }
    } catch (error) {
        dispatch(fetchAllCoursesError(error));
    }
};

export const clearAllCourses = () => ({
    type: actions.CLEAR_DATA,
});
