export const UI = '[UI]';

export const types = {
    START_LOADER: `${UI} start_loader`,
    END_LOADER: `${UI} end_loader`,

    SET_STATUS_LOADING: `${UI} status loading`,
    SET_STATUS_SUCCESS: `${UI} status success`,
    SET_STATUS_FAIL: `${UI} status fail`,
    SET_STATUS_IDLE: `${UI} status idle`,
};

export const startLoader = (entity) => ({
    type: types.START_LOADER,
    payload: entity,
});

export const endLoader = (entity) => ({
    type: types.END_LOADER,
    payload: entity,
});

export const statusLoading = (entity) => ({
    type: types.SET_STATUS_LOADING,
    payload: entity,
});

export const statusSuccess = (entity) => ({
    type: types.SET_STATUS_SUCCESS,
    payload: entity,
});

export const statusFail = (entity) => ({
    type: types.SET_STATUS_FAIL,
    payload: entity,
});

export const statusIdle = (entity) => ({
    type: types.SET_STATUS_IDLE,
    payload: entity,
});
