import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import { list } from './my-courses.module.scss';
import { getAbsolutePath } from '../../../../../routes';
import {
    clearMyCourses,
    fetchMyCourses,
} from '../../../../../redux/courses/actions/my-courses.actions';

import EmptyCourses from '../../../../atoms/empty-courses/empty-courses';
import List from '../../../../molecules/list/list';
import Loader from '../../../../atoms/loader';
import CoursesListItem from '../courses-list-item/courses-list-item';

const MyCourses = () => {
    const dispatch = useDispatch();
    const { loading, courses } = useSelector((state) => {
        return {
            courses: Object.values(state.courses.myCourses.data),
            loading: state.courses.myCourses.loading,
        };
    });

    useEffect(() => {
        dispatch(fetchMyCourses());
        return () => {
            dispatch(clearMyCourses());
        };
    }, [dispatch]);

    if (loading) {
        return <Loader />;
    }

    return courses.length > 0 ? (
        <List listClassName={list} items={courses} ItemComponent={CoursesListItem} />
    ) : (
        <EmptyCourses
            onClick={() => navigate(getAbsolutePath('APP_MY_ACCOUNT_COURSES_ALL'))}
            title="Nie posiadasz jeszcze wykupionych kursów"
            content="Kliknij w przycisk poniżej aby przejść do wszystkich dostępnych kursów."
            buttonContent="ZOBACZ KURSY"
        />
    );
};

export default MyCourses;
