import React, { useEffect, useState } from 'react';

import useIntersect from '../../hooks/useIntersect';

interface IIntersectBoxProps {
    onIntersecting: () => void;
    children?: React.ReactNode;
}

const IntersectBox: React.FC<IIntersectBoxProps> = ({ children, onIntersecting }) => {
    const [intersectionTime, setIntersectionTime] = useState(0);
    const [ref, entry] = useIntersect<HTMLDivElement>({});

    useEffect(() => {
        if (entry?.isIntersecting) {
            // Intersection time is expressed in ms, and it is a double precision value.
            // I think 1ms precision is enough, so I need numbers without fractional part of them
            if (Math.trunc(intersectionTime) !== Math.trunc(entry?.time)) {
                setIntersectionTime(entry?.time);
                onIntersecting();
            }
        }
    }, [entry, onIntersecting, intersectionTime]);

    return <div ref={ref}>{children}</div>;
};

export default IntersectBox;
