import { navigate } from 'gatsby';

import { config } from '../../../config';
import {
    clearOrderData,
    ORDER,
    orderValidate,
    setChosenXsellingPromotions,
    setCurrentDelivery,
    setDeliveryMethods,
    setOrderDiscountCode,
    setPayByPointsBalance,
    setPayByPointsItem,
    types,
} from './order.actions';
import { errorHandler } from '../../../communication/utils';
import { clearAllAlerts, setAlert } from '../../alerts/alerts.actions';

import { prepareOrderData } from '../../../utills/shop-steps-utils';
import { clearCartData, delSingleProduct, mergeCartData, setCartData } from '../cart/cart.actions';
import { getAbsolutePath, ORDER_ROUTES } from '../../../routes';
import { eventMap, prepareContents, trackEvent } from '../../../utills/FbAnalytics';
import { clearBuyingProcessAddresses } from '../../buying-process/step-two/actions/buying-process-step-two-actions';
import { setBuyingProcessGlobalStep } from '../../buying-process/global/actions/buying-process-global-actions';
import { selectCartItems } from '../cart/cart.selectors';
import pick from '../../../utills/pick';
import {
    selectChosenXselling,
    selectDiscountCode,
    selectPayByPointsBalance,
} from './order.selectors';
import { mergeCartItemsWithOrderItems, prepareXSellingPromotions } from './order.utility';
import { addModalAction } from '../../actions/actions-modals';
import { setDiscounts } from '../discounts/discounts.actions';
import { selectPointsBalance } from '../../gamification/gamification.selectors';
import pointsWithText from '../../../utills/gamification/points-with-text';

const {
    SUBMIT,
    CHECK,
    SEND_ORDER_REQUEST,
    SEND_ORDER_SUCCESS,
    SEND_ORDER_FAIL,
    CHECK_ORDER_REQUEST,
    CHECK_ORDER_SUCCESS,
    CHECK_ORDER_FAIL,
    ORDER_VALIDATE,
    GET_DELIVERY_METHODS_REQUEST,
    GET_DELIVERY_METHODS_SUCCESS,
    GET_DELIVERY_METHODS_FAIL,
    SEND_ORDER_VALIDATE_REQUEST,
    SEND_ORDER_VALIDATE_SUCCESS,
    SEND_ORDER_VALIDATE_FAIL,
    CLEAR_XSELLING_PROMOTIONS,
    REQUEST_PAY_BY_POINTS,
} = types;

const { endpoints, formsStatusMap } = config;

const orderMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, error, meta } = action;
    next(action);

    let fetchData, ordersCollection, lastOrderProductsWithSubscr, FbAnalyticsParams, eventName;

    const formikBag = meta?.formikBag || meta?.previousAction?.meta?.formikBag;
    const payloadDiscountCode =
        Array.isArray(payload?.data?.discountCodes) && payload.data.discountCodes[0]
            ? payload.data.discountCodes[0]
            : '';
    const xSellingPromotions = payload?.data?.xSellingPromotions;
    const discountCode = selectDiscountCode(getState());
    const cartItems = selectCartItems(getState());
    const xSellingProductId = selectChosenXselling(getState())?.productId;
    const paymentPointsBalance = selectPayByPointsBalance(getState());
    const pointsBalance = selectPointsBalance(getState());

    switch (type) {
        case SUBMIT:
            dispatch(clearAllAlerts());

            fetchData = {
                types: [SEND_ORDER_REQUEST, SEND_ORDER_SUCCESS, SEND_ORDER_FAIL],
                payload: {
                    request: {
                        method: 'post',
                        url: endpoints.shop.order,
                        params: {
                            expand: `payments`,
                        },
                        data: { ...prepareOrderData(getState()) },
                        withCredentials: true,
                    },
                },
            };
            dispatch(fetchData);
            break;

        case SEND_ORDER_SUCCESS:
            dispatch(clearCartData());
            dispatch(clearBuyingProcessAddresses());
            dispatch(clearOrderData());
            dispatch(setBuyingProcessGlobalStep(0));

            if (payload.data.isRecurringPayment) {
                navigate(
                    `${getAbsolutePath('ORDER_CREDIT_CARD', ORDER_ROUTES)}/${payload.data.id}`,
                    { replace: true }
                );
            } else {
                navigate(payload.data.payments[0].url, { replace: true });
            }
            break;

        case CHECK:
            fetchData = {
                types: [CHECK_ORDER_REQUEST, CHECK_ORDER_SUCCESS, CHECK_ORDER_FAIL],
                payload: {
                    request: {
                        url: endpoints.shop.order,
                        params: {
                            expand: `items,items.product`,
                        },
                        withCredentials: true,
                    },
                },
            };
            dispatch(fetchData);
            break;

        case CHECK_ORDER_SUCCESS:
            ordersCollection = payload.data.items
                .filter((item) => item.status === 0)
                .sort((a, b) => b.createdAt - a.createdAt);

            if (ordersCollection.length > 0) {
                lastOrderProductsWithSubscr = ordersCollection[0].items
                    .map((item) => item.product)
                    .filter((item) => item.businessMeaning === 1);
                if (lastOrderProductsWithSubscr.length > 0) {
                    eventName = eventMap.subscribe;
                    FbAnalyticsParams = {
                        currency: 'PLN',
                        value: ordersCollection[0].price.gross,
                    };
                } else {
                    eventName = eventMap.purchase;
                    FbAnalyticsParams = {
                        content_type: 'product',
                        contents: prepareContents(ordersCollection[0].items),
                        currency: 'PLN',
                        value: ordersCollection[0].price.gross,
                    };
                }
                trackEvent(eventName, FbAnalyticsParams);
            }
            break;

        case SEND_ORDER_FAIL:
            const errors = errorHandler(action.error);
            errors.map((err) => dispatch(setAlert(err, ORDER)));
            break;

        case mergeCartData().type:
        case setCartData().type:
            fetchData = {
                types: [
                    GET_DELIVERY_METHODS_REQUEST,
                    GET_DELIVERY_METHODS_SUCCESS,
                    GET_DELIVERY_METHODS_FAIL,
                ],
                payload: {
                    request: {
                        url: endpoints.shop.delivery, //applying discount codes uses validate endpoint
                        method: 'POST',
                        data: { items: cartItems },
                        withCredentials: true,
                    },
                },
                meta,
            };
            dispatch(fetchData);
            break;

        case GET_DELIVERY_METHODS_SUCCESS:
            dispatch(setDeliveryMethods(payload.data));
            if (Array.isArray(payload.data) && payload.data.length === 1) {
                dispatch(setCurrentDelivery(payload.data[0].id));
            }

            break;

        case GET_DELIVERY_METHODS_FAIL:
            errorHandler(error).forEach((errorData) => dispatch(setAlert(errorData)));
            break;

        case ORDER_VALIDATE:
            fetchData = {
                types: [
                    SEND_ORDER_VALIDATE_REQUEST,
                    SEND_ORDER_VALIDATE_SUCCESS,
                    SEND_ORDER_VALIDATE_FAIL,
                ],
                payload: {
                    request: {
                        url: endpoints.shop.orderValidate, //applying discount codes uses validate endpoint
                        method: 'POST',
                        data: {
                            ...prepareOrderData(getState()),
                            ...payload,
                        },
                        params: {
                            expand: `items,items.media,items.product,items.variant,items.discounts,items.productPromotions`,
                        },
                        withCredentials: true,
                    },
                },
                meta: { ...meta },
            };
            dispatch(fetchData);
            break;
        case SEND_ORDER_VALIDATE_SUCCESS:
            if (payloadDiscountCode !== discountCode) {
                dispatch(setOrderDiscountCode(payloadDiscountCode));

                if (formikBag) {
                    formikBag.setStatus(formsStatusMap.success);
                }

                if (payloadDiscountCode.length > 0) {
                    dispatch(
                        setAlert({
                            type: 'success',
                            content: `Zastosowano kod rabatowy „${payloadDiscountCode}`,
                        })
                    );
                }
            }

            if (Array.isArray(xSellingPromotions) && xSellingPromotions.length > 0) {
                const { productIds, promotionsByProductId } = prepareXSellingPromotions(
                    xSellingPromotions
                );

                dispatch(setDiscounts(promotionsByProductId));

                dispatch(
                    addModalAction({
                        modalKey: 'UPSELLING_PRODUCTS_MODAL',
                        modalProps: { productIds },
                    })
                );
            }

            if (cartItems && payload.data.items) {
                dispatch(
                    mergeCartData({
                        ...pick(payload.data, [
                            'installmentPrice',
                            'installmentBasePrice',
                            'price',
                            'basePrice',
                        ]),
                        items: mergeCartItemsWithOrderItems(cartItems, payload.data.items, [
                            'basePrice',
                            'price',
                            'basePriceTotal',
                            'priceTotal',
                            'discounts',
                        ]),
                    })
                );
            }
            break;
        case SEND_ORDER_VALIDATE_FAIL:
            if (formikBag) {
                formikBag.setStatus(formsStatusMap.fail);
                formikBag.setErrors({
                    discountCode: 'Nieprawidłowy kod',
                });
            }

            errorHandler(error).forEach((errorData) => dispatch(setAlert(errorData)));
            break;
        case CLEAR_XSELLING_PROMOTIONS:
            if (xSellingProductId) {
                const cartItemToDelete = cartItems.find(
                    (cartItem) => cartItem.productId === xSellingProductId
                );

                if (cartItemToDelete) {
                    dispatch(
                        delSingleProduct(
                            pick(cartItemToDelete, ['productId', 'variantId', 'quantity'])
                        )
                    );
                }
                dispatch(setChosenXsellingPromotions({}));
            }
            break;
        case REQUEST_PAY_BY_POINTS:
            if (paymentPointsBalance + payload.amount <= pointsBalance) {
                dispatch(setPayByPointsItem(payload));

                if (payload.checked) {
                    dispatch(setPayByPointsBalance(payload.amount));
                } else {
                    dispatch(setPayByPointsBalance(-payload.amount));
                }

                if (payload.validateAfter) {
                    dispatch(orderValidate());
                }
            } else {
                dispatch(
                    addModalAction({
                        type: 'error',
                        title: 'Za mało Ketonów by skorzystać z promocji.',
                        content: `Brakuje Ci jeszcze ${pointsWithText(
                            paymentPointsBalance + payload.amount - pointsBalance
                        )}. Nie martw się, możesz kupić ten produkt w regularnej cenie.`,
                        buttonText: 'Rozumiem',
                    })
                );
                dispatch(setPayByPointsItem({ itemId: payload.itemId, disabled: true }));
            }
            break;
        // no default
    }
};

export default orderMiddleware;
