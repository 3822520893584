import React from 'react';

import { link, active, labelBox, accountNav } from './account-nav.module.scss';

import CustomLink from '../../hoc/custom-link';
import CounterDot from '../../atoms/counter-dot';

const AccountNav = ({ navItems }) => {
    return (
        <nav className={accountNav}>
            <ul>
                {navItems.map((item, index) => {
                    return (
                        <li key={`account-nav-item-${index}`}>
                            <CustomLink
                                to={item.absolutePath}
                                className={link}
                                currentClassName={active}
                                partiallyCurrentClassName={active}
                            >
                                <span className={labelBox}>
                                    {item.label}
                                    {item.routeProps?.counter && (
                                        <CounterDot type={item.routeProps.counter} />
                                    )}
                                </span>
                            </CustomLink>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default AccountNav;
