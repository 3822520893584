export const currentMovieCourseIndex = (currentCourseState) => {
    const currentId = currentCourseState.chosenMovie.id;
    const movies = currentCourseState.chosenCategoryData.movies;

    let res = movies.map((item) => item.id).indexOf(currentId);
    return ++res;
};

export const selectCourseCurrentMovie = (state) =>
    state.courses.currentCourse.chosenMovie;
