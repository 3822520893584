import React from 'react';
import PropTypes from 'prop-types';

import {
    title as titleClass,
    titleHolder,
    wrapper,
    name,
    imageHolder,
} from './trainer.module.scss';

import Avatar from '../../../atoms/avatar';

const Trainer = ({ data }) => {
    const { title, firstName, lastName, description, avatarUri } = data;

    return (
        <div className={wrapper}>
            <div className={imageHolder}>
                <Avatar avatarUri={avatarUri} size="big" />
            </div>
            <div>
                <div>
                    <span className={name}>
                        {firstName}&nbsp;{lastName}
                    </span>
                </div>
                <div className={titleHolder}>
                    <span className={titleClass}>{title}</span>
                </div>
                <div>{description}</div>
            </div>
        </div>
    );
};

Trainer.propTypes = {
    data: PropTypes.object,
};

export default Trainer;
