import { entitiesMap, getFullActionName } from '../../reusable-reducers/entities-map';
import { getMyCourses } from '../../../communication/courses';

const actions = {
    FETCH_START: getFullActionName(
        entitiesMap.myCourses.baseName,
        entitiesMap.myCourses.methodName.fetch,
        entitiesMap.myCourses.allowedActions.start
    ),
    FETCH_SUCCESS: getFullActionName(
        entitiesMap.myCourses.baseName,
        entitiesMap.myCourses.methodName.fetch,
        entitiesMap.myCourses.allowedActions.success
    ),
    FETCH_ERROR: getFullActionName(
        entitiesMap.myCourses.baseName,
        entitiesMap.myCourses.methodName.fetch,
        entitiesMap.myCourses.allowedActions.error
    ),
    CLEAR_DATA: getFullActionName(
        entitiesMap.myCourses.baseName,
        entitiesMap.myCourses.methodName.fetch,
        entitiesMap.myCourses.allowedActions.clearData
    ),
};

export const fetchMyCoursesStart = () => ({
    type: actions.FETCH_START,
});

const fetchMyCoursesSuccess = (payload) => ({
    type: actions.FETCH_SUCCESS,
    payload,
});

const fetchMyCoursesError = (payload) => ({
    type: actions.FETCH_ERROR,
    payload,
});

export const fetchMyCourses = () => async (dispatch) => {
    dispatch(fetchMyCoursesStart());

    try {
        const res = await getMyCourses();

        if (res.status === 200) {
            dispatch(fetchMyCoursesSuccess(res.data));
        }
    } catch (error) {
        dispatch(fetchMyCoursesError(error));
    }
};

export const clearMyCourses = () => ({
    type: actions.CLEAR_DATA,
});
