import React, { useEffect, useState } from 'react';

import {
    header,
    link,
    active,
    title,
    bar,
    barOpen,
    barItem,
    barButton,
    barButtonBox,
    barDropdown,
    barVisibleBox,
    barButtonChevron,
    barButtonDots,
    dashboard,
    barContainer,
    linkContent,
    container,
} from './dashboard.module.scss';
import { getRouteNodeAbsoluteNav } from '../../../routes';
import ChevronIcon from '../../../assets/images/svg/chevron-down.svg';

import FlexBar from '../../molecules/flex-bar';
import CustomLink from '../../hoc/custom-link';

const Dashboard = ({ routeData, children, uri, location }) => {
    const [activeRouteIndex, setActiveRouteIndex] = useState(null);

    const dashboardNavItems = getRouteNodeAbsoluteNav(routeData.key);

    const barClasses = {
        bar: bar,
        barOpen: barOpen,
        item: barItem,
        button: barButton,
        buttonBox: barButtonBox,
        dropdown: barDropdown,
        visibleBox: barVisibleBox,
    };

    const buttonContent = (
        <>
            <div className={barButtonChevron}>
                <ChevronIcon />
            </div>
            <span className={barButtonDots}>...</span>
        </>
    );

    useEffect(() => {
        const getActiveRouteIndex = () => {
            if (uri === location.pathname) {
                return 0;
            }

            return routeData.routes.findIndex((route) => location.pathname.includes(route.path));
        };
        setActiveRouteIndex(getActiveRouteIndex());
    }, [uri, location.pathname, routeData.routes]);

    return (
        <div className={dashboard}>
            <div className={header}>
                <h2 className={title}>{routeData.label}</h2>
                <div className={barContainer}>
                    {!routeData.hideNav && dashboardNavItems.length > 1 && (
                        <FlexBar
                            classes={barClasses}
                            buttonContent={buttonContent}
                            activeItemIndex={activeRouteIndex}
                        >
                            {dashboardNavItems.map((item, index) => {
                                return (
                                    <CustomLink
                                        to={item.absolutePath}
                                        className={link}
                                        currentClassName={active}
                                        partiallyCurrentClassName={active}
                                        key={`account-dashboard-link-box-${index}`}
                                    >
                                        <div className={linkContent}>{item.label}</div>
                                    </CustomLink>
                                );
                            })}
                        </FlexBar>
                    )}
                </div>
            </div>
            <div className={container}>{children}</div>
        </div>
    );
};

export default Dashboard;
