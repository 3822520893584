import pick from '../../../utills/pick';
import { arrayToObject, pickField } from '../../../utills/data-convertion';

export const mergeCartItemsWithOrderItems = (cartItems, orderItems, fields) => {
    return cartItems.map((cartItem) => {
        const orderItem = orderItems.find((item) => item.productId === cartItem.productId);

        if (orderItem) {
            return {
                ...cartItem,
                ...pick(orderItem, fields),
            };
        }

        return cartItem;
    });
};

export const prepareXSellingPromotions = (xSellingPromotions) => {
    const productIds = xSellingPromotions.map(pickField('productIds')).flat();
    const products = xSellingPromotions.map(pickField('products')).flat();

    const productsObject = arrayToObject(products, 'productId');

    // create discount object from xSellingPromotions by productId because redux shop.discounts needs such structure
    const promotionsByProductId = productIds.reduce((promotions, currentId) => {
        const currentPromotions = xSellingPromotions.filter((promotion) =>
            promotion.productIds.includes(currentId)
        );

        if (currentPromotions.length === 0) {
            return promotions;
        }

        return {
            ...promotions,
            [currentId]: {
                price: productsObject[currentId].price,
                basePrice: productsObject[currentId].basePrice,
                // We get only last promotion. Just in case. API shouldn't return two products assigned to two promotions.
                // But who can trust API and who really knows...
                promotions: currentPromotions.slice(-1),
            },
        };
    }, {});

    return {
        productIds,
        promotionsByProductId,
        products,
    };
};
